/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Form, Input, Select, Checkbox, Typography, Space } from 'antd';
import ReactGA from 'react-ga4';
import { EditFilled } from '@ant-design/icons';
import BorderButton from './BorderButton';

import { gtagConversion } from '../../util/gtag';
import NpiLookup from './NpiLookup';
import config from '../../config';

const { TextArea } = Input;
const { Option } = Select;

const { Text, Title, Link } = Typography;

const Styles = {
  tailLayout: {
    wrapperCol: {
      offset: 0,
      span: 24
    }
  },
  formItemStyle: {
    width: '50%',
    marginRight: 5
  },
  tag: {
    background: '#dfeaef',
    marginTop: 10
  }
};

const requiredRule = {
  required: true,
  message: 'This field is required!'
};

const RegisterAndProfileForm = (props) => {
  const {
    formType,
    user,
    userFromLookup,
    regSessionType,
    isVirtualHybrid,
    form,
    error,
    errorText,
    profileFormActive,
    setProfileFormActive,
    dropDownValues,
    submitting
  } = props;
  const isRegForm = formType === 'register';

  const code = config.getUrlParams('code');

  React.useEffect(() => {
    if (userFromLookup) {
      form.setFieldsValue({
        ...userFromLookup,
        specialty: userFromLookup.specialties,
        degree: userFromLookup.degrees,
        communication_consent: true
      });
    }
  }, [userFromLookup]); // eslint-disable-line

  // const [isEligible, setIsEligible] = React.useState(true);
  const [otherSpecialtyVisible, setOtherSpecialtyVisible] = React.useState(false);
  const [otherDegreeVisible, setOtherDegreeVisible] = React.useState(false);
  const [otherReferralVisible, setOtherReferralVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [attendanceType, setAttendanceType] = React.useState(null);

  // const [showNpiLookupCopy, setShowNpiLookupCopy] = React.useState(false);
  const [npiLookupModalOpen, setNpiLookupModalOpen] = React.useState(false);
  // const [npiLookupEmpty, setNpiLookupEmpty] = React.useState(false);

  const [hasNpiFieldDisabled, setHasNpiFieldDisabled] = React.useState(false);
  const [showNpiField, setShowNpiField] = React.useState(false);
  // const [isNpiRequired, setIsNpiRequired] = React.useState(false);

  const toggleEdit = () => {
    setEditMode(!editMode);
    ReactGA.event({
      category: 'Profile',
      action: !profileFormActive ? 'Profile Edit Opened' : 'Profile Edit Closed'
    });
    setProfileFormActive(!profileFormActive);
  };

  // const onFormFinishFailed = (errorInfo) => {
  //   console.log('onEmailFormFinishFailed Failed:', errorInfo);
  // };

  // useEffect(() => {
  //   if (user.npi && user.npi.length > 0 && !form.getFieldValue('npi')) {
  //     form.setFieldsValue({ npi: user.npi });
  //     setShowNpiField(true);
  //   }
  // }, [user]); // eslint-disable-line

  const onVirtualHybridChange = (label) => {
    return (e) => {
      if (e.target.checked) {
        setAttendanceType(label);
      }
    };
  };

  const onValuesChange = (values) => {
    // console.log('values', values, regSessionType, values.attendee_type);

    // setIsEligible(!(regSessionType === 'Out-Of-Office' && values.attendee_type === 7));

    // console.log(
    //   'intersection of values degree and the list of npirequiredfields',
    //   _.intersection(values.degree, config.npiRequiredDegrees)
    // );

    // const degreeRequiresNpi =
    //   _.intersection(form.getFieldValue('degree'), config.npiRequiredDegrees).length > 0;
    // hardcoding here so in case they change their minds we still have the logic above this line

    const degreeRequiresNpi = false;

    const attendee_type_value = form.getFieldValue('attendee_type');
    const hasNpiFieldValue = form.getFieldValue('hasNpi');

    if (degreeRequiresNpi && attendee_type_value !== 1) {
      form.setFieldsValue({ attendee_type: 1 });
    }

    let hasNpiFieldYesNo =
      (_.includes([1, 6], attendee_type_value) && !_.isNumber(hasNpiFieldValue)) ||
      degreeRequiresNpi
        ? 1
        : null;

    if (
      // office staff can select yes no.
      (attendee_type_value === 7 && !degreeRequiresNpi) ||
      // allied hcp will be set to yes on attendee type selection but with option to select no.
      // if there is a selection we wont change it
      (attendee_type_value === 6 && _.isNumber(hasNpiFieldValue))
    ) {
      // debugger;
      hasNpiFieldYesNo = null;
    }

    if (hasNpiFieldYesNo !== null && hasNpiFieldValue !== hasNpiFieldYesNo) {
      form.setFieldsValue({ hasNpi: hasNpiFieldYesNo });
    }

    // if we switch from hcp or allied hcp to office staff we reset the hasNpi field
    if (values.attendee_type === 7) {
      form.setFieldsValue({ hasNpi: null });
      form.setFieldsValue({ npi: null });
    }
    // if we stich from anywhere to prescriber we set hasNpi to yes
    if (values.attendee_type === 1) {
      // debugger;
      form.setFieldsValue({ hasNpi: 1 });
    }

    let showNpiFieldValue =
      hasNpiFieldValue === 1 ||
      hasNpiFieldYesNo === 1 ||
      degreeRequiresNpi ||
      !values.attendanceType === 7 ||
      values.attendee_type === 1 ||
      (!_.isNumber(hasNpiFieldValue) && !isRegForm);

    if (values.attendee_type === 7) {
      showNpiFieldValue = false;
    }

    setShowNpiField(showNpiFieldValue);

    setHasNpiFieldDisabled(attendee_type_value === 1 || degreeRequiresNpi);

    if (values.specialty !== undefined) {
      console.log('values.specialty', values.specialty);
      if (_.includes(values.specialty, 'Other')) {
        !otherSpecialtyVisible && setOtherSpecialtyVisible(true);
      } else {
        otherSpecialtyVisible && setOtherSpecialtyVisible(false);
      }
    }
    if (values.degree !== undefined) {
      if (_.includes(values.degree, 'Other')) {
        !otherDegreeVisible && setOtherDegreeVisible(true);
      } else {
        otherDegreeVisible && setOtherDegreeVisible(false);
      }
    }
    if (values.attendeereferral !== undefined) {
      if (values.attendeereferral === 'Other') {
        !otherReferralVisible && setOtherReferralVisible(true);
      } else {
        otherReferralVisible && setOtherReferralVisible(false);
      }
    }
  };

  const openNpiLookupModal = () => {
    const requiredFields = ['first_name', 'last_name', 'city', 'state', 'zip_code'];
    const isValid = _.every(_.values(_.pick(form.getFieldsValue(), requiredFields)));
    if (isValid) {
      setNpiLookupModalOpen(true);
    } else {
      form.validateFields(requiredFields);
    }
  };

  const setNpiInfo = (hcpInfo) => {
    if (hcpInfo) {
      form.setFieldsValue({ ...hcpInfo });
    }
    setNpiLookupModalOpen(false);
  };

  console.log('user', user);

  const initialValues = user
    ? {
        ...user,
        specialty: user.specialties,
        degree: user.degrees,
        communication_consent: false,
        patients_treatment_agreement: false,
        speaker_relation_certification: false
      }
    : {
        communication_consent: false,
        patients_treatment_agreement: false,
        speaker_relation_certification: false
      };
  // const idRoleCheckboxHandler = (event) => {};

  // console.log('dropdownValues', dropDownValues);

  const localOnFormFinish = (values) => {
    values.attendanceType = attendanceType;
    props.onFormFinish(values);
  };

  const renderDegreeAndSpecialtyFields = () => {
    return (
      <>
        <Form.Item
          label="Specialty"
          name="specialty"
          required
          rules={[requiredRule]}
          style={!isRegForm && Styles.formItemStyle}
        >
          <Select
            mode="multiple"
            placeholder={'Click here to search'}
            disabled={!isRegForm && !profileFormActive}
            showArrow
          >
            {dropDownValues.specialties &&
              dropDownValues.specialties.map((specialty) => {
                return (
                  <Option value={specialty.name} key={specialty.id.toString()}>
                    {specialty.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        {otherSpecialtyVisible && (
          <Form.Item
            required
            label="Other (please specify)"
            name="other_specialty"
            rules={[requiredRule]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label="Degree"
          name="degree"
          required
          rules={[requiredRule]}
          style={!isRegForm && Styles.formItemStyle}
        >
          <Select
            mode="multiple"
            placeholder={'Please select'}
            disabled={!isRegForm && !profileFormActive}
            showArrow
          >
            {dropDownValues.degrees &&
              dropDownValues.degrees.map((degree) => {
                return (
                  <Option value={degree} key={degree}>
                    {degree}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        {otherDegreeVisible && (
          <Form.Item
            required
            label="Other (please specify)"
            name="other_degree"
            rules={[requiredRule]}
          >
            <Input />
          </Form.Item>
        )}
      </>
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="register_form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      onFinish={localOnFormFinish}
      // onFinishFailed={onFormFinishFailed}
      scrollToFirstError
      className="profile-reg-form"
    >
      <NpiLookup
        formValues={form.getFieldsValue()}
        modalOpen={npiLookupModalOpen}
        setNpiInfo={setNpiInfo}
        handleModalClose={() => {
          setNpiLookupModalOpen(false);
        }}
      />
      <Space direction="vertical" style={{ width: '100%' }}>
        {isRegForm && (
          <Form.Item>
            <Title style={{ marginBottom: 0 }} level={4}>
              Visiting the Psych Center for the first time?
            </Title>
            <br />
            <Text
              style={{
                marginBottom: 5,
                marginTop: 5,
                display: 'block',
                fontFamily: 'Roboto-Light'
              }}
            >
              In order to register for the specific program that interests you, as well as access
              the Psych Center&apos;s many useful features, please fill out the form below.
              <span
                style={{
                  color: '#2C5773',
                  fontWeight: 900,
                  fontFamily: 'Roboto-Bold'
                }}
              >
                {' '}
                You&apos;ll only have to register once for full access to the Psych Center.
              </span>
            </Text>
            <br />
            <Text style={{ fontFamily: 'Roboto-Light' }}>
              <span style={{ fontFamily: 'SimSun, sans-serif' }}>*</span>Indicates a required field
            </Text>
          </Form.Item>
        )}

        <div
          style={
            !isRegForm
              ? {
                  border: '1px solid',
                  borderRadius: 5,
                  padding: 5,
                  backgroundColor: profileFormActive ? '#e5f6f8' : 'white'
                }
              : {}
          }
        >
          {!isRegForm && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              *Indicates a required field
              <Link onClick={toggleEdit}>
                Edit <EditFilled />
              </Link>
            </div>
          )}
          <div className="flex">
            <Form.Item
              label="First Name"
              name="first_name"
              required
              style={Styles.formItemStyle}
              rules={[requiredRule]}
            >
              <Input maxLength={35} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Middle Name" name="middle_name" style={Styles.formItemStyle}>
              <Input disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item
              label="Last Name"
              name="last_name"
              required
              style={Styles.formItemStyle}
              rules={[requiredRule]}
            >
              <Input maxLength={35} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Suffix" name="suffix" style={Styles.formItemStyle}>
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item
              label="City"
              name="city"
              required
              rules={[requiredRule]}
              style={{ width: '33%', marginRight: 5 }}
            >
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              required
              rules={[requiredRule]}
              style={{ width: '33%', marginRight: 5 }}
            >
              <Select placeholder={'Select a State'} disabled={!isRegForm && !profileFormActive}>
                {dropDownValues.states &&
                  dropDownValues.states.map((state) => {
                    return (
                      <Option key={state.abrv} value={state.abrv}>
                        {state.state}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Zip"
              name="zip_code"
              required
              rules={[
                requiredRule,
                {
                  validator: (rule, value) => {
                    if (
                      (value && value.length < 5) ||
                      (value && value.length > 10) ||
                      (value && !_.isNumber(parseInt(value.trim(), 10)))
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Enter 5 digits.');
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              style={{ width: '33%', marginRight: 5 }}
            >
              <Input maxLength={10} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>
          {!isRegForm && renderDegreeAndSpecialtyFields()}

          <div className="flex">
            <Form.Item
              label="Identify the role that best describes you (select one)"
              name="attendee_type"
              extra={
                isRegForm && (
                  <div style={{ marginTop: 5 }}>
                    <div>Appropriate attendees for an out-of-office program are:</div>
                    <div>
                      <ul style={{ marginBottom: 8, paddingLeft: 17 }}>
                        <li>Individuals with prescribing authority</li>
                        <li>Licensed professional counselors</li>
                        <li>Medical, Nurse Practitioner, and Physician Assistant Students</li>
                        <li>Nurses (RN, LPN)</li>
                        <li>Social workers</li>
                        <li>Psychologists</li>
                        <li>Pharmacists (PharmD, RPh)</li>
                      </ul>
                      <div>Inappropriate attendees for an out-of-office program include:</div>
                      <ul style={{ marginBottom: 8, paddingLeft: 17 }}>
                        <li>Medical assistants</li>
                        <li>Coordinators</li>
                        <li>Office managers</li>
                        <li>
                          Billing and coding specialists, including those who have
                          <br /> responsibility for prior authorization forms
                        </li>
                        <li>Pharmacy techs</li>
                        <li>Administrative assistants</li>
                        <li>Appointment managers and schedulers</li>
                        <li>Front office coordinators</li>
                        <li>Receptionists</li>
                      </ul>
                      <p>
                        Registrations may be reviewed and/or canceled based on Intra-Cellular
                        Therapies policies.
                      </p>
                      {/* {!isEligible && (
                    <p style={{ fontWeight: 800, fontSize: 18 }}>
                      You are not eligible for this event. Visit our{' '}
                      <a href="/anon/upcoming-events">Upcoming Virtual Events</a> for other sessions
                      that may interest you.
                    </p>
                  )} */}
                    </div>
                  </div>
                )
              }
              required
              rules={[requiredRule]}
              style={{
                ...Styles.formItemStyle,
                textAlign: 'left',
                justifyContent: 'flex-start',
                minWidth: '50%'
              }}
            >
              <Select disabled={!isRegForm}>
                <Option value={1}>Prescriber</Option>
                <Option value={6}>
                  Allied healthcare professional (RN; LPN; medical, NP, or PA students; pharmacist;
                  psychologist; social worker)
                </Option>
                <Option value={7}>
                  Office Staff, Non-Allied Healthcare Professional (medical assistant, coordinator,
                  office managers, billing and coding specialist, including those who have
                  responsibility for prior authorization forms, pharmacy tech, administrative
                  assistant, appointment manager and scheduler, front office coordinator,
                  receptionist)
                </Option>
              </Select>
            </Form.Item>
            <div className="flex flex-column">
              {isRegForm && (
                <Form.Item
                  label="Do you have an NPI number?"
                  name="hasNpi"
                  // required
                  // style={Styles.formItemStyle}
                  disabled={hasNpiFieldDisabled}
                >
                  <Select disabled={(!isRegForm && !profileFormActive) || hasNpiFieldDisabled}>
                    <Option value={1}>Yes</Option>
                    <Option value={0}>No</Option>
                  </Select>
                </Form.Item>
              )}
              {(showNpiField ||
                (!isRegForm && form.getFieldValue('npi')) ||
                (isRegForm && showNpiField)) && (
                <Form.Item
                  label="NPI"
                  disabled={!isRegForm}
                  extra={
                    isRegForm && (
                      <div style={{ marginTop: 5 }}>
                        Please fill out the above information (name and address) and{' '}
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={openNpiLookupModal}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                          }}
                        >
                          click here
                        </span>
                        . The system will look up your information and autofill the remainder of the
                        form, including your NPI number.
                      </div>
                    )
                  }
                  name="npi"
                  rules={
                    isRegForm && [
                      requiredRule,
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (!value) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('NPI is required.');
                          }
                          if (value && !/^\d{10}$/.test(value)) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('NPI needs to be 10 digits.');
                          }
                          return Promise.resolve();
                        }
                      })
                    ]
                  }
                >
                  <Input disabled={!isRegForm} />
                </Form.Item>
              )}
              {isRegForm && (
                <div>
                  <Form.Item
                    label="How did you hear about the Psych Center?"
                    name="attendeereferral"
                    // required
                    // style={Styles.formItemStyle}
                  >
                    <Select disabled={!isRegForm && !profileFormActive}>
                      <Option value="E-Psychiatry">E-Psychiatry</Option>
                      <Option value="ENP Network">ENP Network</Option>
                      {/* <Option value="Treatment Perspectives">Treatment Perspectives</Option> */}
                      <Option value="Intra-Cellular Therapies Sales Representative">
                        Intra-Cellular Therapies Sales Representative
                      </Option>
                      <Option value="Peer/Colleague">Peer/Colleague</Option>
                      <Option value="Other">Other (please list):</Option>
                    </Select>
                  </Form.Item>
                  {otherReferralVisible && (
                    <Form.Item
                      required
                      name="other_attendeereferral"
                      label="Other (please specify)"
                    >
                      <Input />
                    </Form.Item>
                  )}
                </div>
              )}
              {isRegForm && renderDegreeAndSpecialtyFields()}
            </div>
          </div>

          <Form.Item
            label="Practice Name/Affiliation"
            name="affiliation"
            required
            rules={[requiredRule]}
          >
            <Input maxLength={100} disabled={!isRegForm && !profileFormActive} />
          </Form.Item>

          <div className="flex">
            <Form.Item
              label="Office Address"
              name="address"
              required
              rules={[requiredRule]}
              style={Styles.formItemStyle}
            >
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Office Address 2" name="address2" style={Styles.formItemStyle}>
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item label="Licensed State" name="licensed_state" style={Styles.formItemStyle}>
              <Select placeholder={'Select a State'} disabled={!isRegForm && !profileFormActive}>
                {dropDownValues.states &&
                  dropDownValues.states.map((state) => {
                    return (
                      <Option key={state.abrv} value={state.abrv}>
                        {state.state}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="License #" name="license_number" style={Styles.formItemStyle}>
              <Input maxLength={30} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item label="Office Phone" name="office_phone" style={Styles.formItemStyle}>
              <Input maxLength={15} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Office Fax" name="fax" style={Styles.formItemStyle}>
              <Input maxLength={15} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item
              label="Mobile Phone"
              name="home_phone"
              required
              rules={[requiredRule]}
              style={Styles.formItemStyle}
            >
              <Input maxLength={15} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            {/* This is the second email field */}
            {isRegForm && (
              <Form.Item
                label="Email"
                name="email"
                required
                rules={[
                  requiredRule,
                  {
                    type: 'email',
                    message: 'The entered value is not a valid email address.'
                  }
                ]}
                style={Styles.formItemStyle}
              >
                <Input maxLength={100} />
              </Form.Item>
            )}
          </div>

          <Form.Item
            label="Dietary Restrictions"
            name="dietary_restrictions"
            rules={[
              {
                validator: (rule, value) => {
                  if (value && value.length > 50) {
                    return Promise.reject(
                      new Error('This field can have a maximum of 50 characters')
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TextArea rows={3} disabled={!isRegForm && !profileFormActive} />
          </Form.Item>

          <Form.Item
            label="Comments"
            name="comments"
            rules={[
              {
                validator: (rule, value) => {
                  if (value && value.length > 50) {
                    return Promise.reject(
                      new Error('This field can have a maximum of 50 characters')
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TextArea rows={3} disabled={!isRegForm && !profileFormActive} />
          </Form.Item>

          {isRegForm && (
            <>
              <Form.Item
                name="patients_treatment_agreement"
                valuePropName="checked"
                rules={[
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    validator: (__, value) => {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Please agree to the above'));
                    }
                  }
                ]}
                style={{ textAlign: 'left', marginBottom: 0 }}
              >
                <Checkbox style={{ marginLeft: 0 }}>
                  <b style={{ fontWeight: 'bold' }}>
                    I certify that I treat patients over the age of 18 in my practice who have
                    bipolar depression (bipolar I or II) or schizophrenia.
                  </b>
                </Checkbox>
              </Form.Item>
              {regSessionType && (
                <Form.Item
                  name="speaker_relation_certification"
                  valuePropName="checked"
                  rules={[
                    {
                      // eslint-disable-next-line no-confusing-arrow
                      validator: (__, value) => {
                        return value
                          ? Promise.resolve()
                          : Promise.reject(new Error('Please agree to the above'));
                      }
                    }
                  ]}
                  style={{ textAlign: 'left', marginBottom: 0 }}
                >
                  <Checkbox style={{ marginLeft: 0, lineHeight: 1.1 }}>
                    <b style={{ fontWeight: 'bold' }}>
                      I certify that I am not an employee of the speaker&apos;s own medical practice
                      (same physical location) or a staff member of a facility for which the speaker
                      is a medical director (whose job function it is to educate those within her or
                      his supervisory authority).
                    </b>
                  </Checkbox>
                </Form.Item>
              )}
              <Form.Item
                name="communication_consent"
                valuePropName="checked"
                rules={[
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    validator: (__, value) => {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Please agree to the communication consent'));
                    }
                  }
                ]}
                style={{ textAlign: 'left' }}
              >
                <Checkbox style={{ marginLeft: 0 }}>
                  <b style={{ fontWeight: 'bold' }}>
                    I give consent for the use of my name, email, and mobile number for
                    event-related information including program invitations, reminders, and upcoming
                    events.
                  </b>
                </Checkbox>
              </Form.Item>
              <div style={{ height: 15 }} />
              {isVirtualHybrid && (
                <Form.Item
                  name="attendanceType"
                  valuePropName="checked"
                  rules={[
                    {
                      // eslint-disable-next-line no-confusing-arrow
                      validator: () => {
                        // console.log('attendanceType', attendanceType);
                        return attendanceType
                          ? Promise.resolve()
                          : Promise.reject(new Error('Please select an option'));
                      }
                    }
                  ]}
                  style={{ textAlign: 'left', marginBottom: 0 }}
                  label={
                    <b style={{ fontWeight: 'bold' }}>
                      The program you are registering for is hybrid and can accomodate both
                      in-person and virtual attendees. Please confirm how you are planning to attend
                      by choosing from the options below.
                    </b>
                  }
                >
                  <Checkbox
                    style={{ marginLeft: 0, lineHeight: 1.1 }}
                    onChange={onVirtualHybridChange('in-person')}
                    checked={attendanceType === 'in-person'}
                  >
                    <span style={{ fontWeight: 'bold' }}>In Person</span>
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0, lineHeight: 1.1 }}
                    onChange={onVirtualHybridChange('virtual')}
                    checked={attendanceType === 'virtual'}
                  >
                    <span style={{ fontWeight: 'bold' }}>Virtual</span>
                  </Checkbox>
                </Form.Item>
              )}
            </>
          )}

          {error && (
            <p style={{ color: 'red', textAlign: 'center' }}>
              {errorText || 'Something went wrong... please try again later!'}
            </p>
          )}
          {(isRegForm || profileFormActive) && (
            <Form.Item {...Styles.tailLayout}>
              <BorderButton
                text={isRegForm ? 'Register' : 'Update'}
                htmlType="submit"
                disabled={submitting}
                wrapperStyle={{ marginBottom: 8, maxWidth: 550, margin: 'auto' }}
                onClick={() => {
                  if (isRegForm) {
                    if (config.webcastCodes.includes(code)) {
                      gtagConversion('DC-9785414/event0/itci-001+per_session');
                    }
                  }
                }}
              />
            </Form.Item>
          )}
        </div>
      </Space>
    </Form>
  );
};

const mapStateToProps = ({ core, auth }) => {
  const { dropDownValues } = core;
  const { userFromLookup, regSessionType, isVirtualHybrid } = auth;
  return { dropDownValues, userFromLookup, regSessionType, isVirtualHybrid };
};

export default withRouter(connect(mapStateToProps, null)(RegisterAndProfileForm));
