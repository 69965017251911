import React from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
// import { Divider } from 'antd';
import config from '../../config';
// import { SessionCard, VideoModal } from '../common';
import { SessionCard, InfoModal } from '../common';
import { setData } from '../../redux/dataSlice';
// import { gtagConversion } from '../../util/gtag';

const TopSessionComponent = (props) => {
  const { auth, data, setData } = props;
  const { mySessions, sessions } = data;
  const code = config.getUrlParams('code');
  const confirmed_non_affiliation = config.getUrlParams('confirmed_non_affiliation') === '1';

  const isVirtualHybrid = config.getUrlParams('isVirtualHybrid') || 0;
  // const [videoModalOpen, setVideoModalState] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState({ loading: false, loaded: false });
  const [session, setSession] = React.useState(null);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState(null);
  const [registerModalOpen, setRegisterModalOpen] = React.useState(false);

  const isVideoOpen = config.getUrlParams('openvideo') === 'true';
  const attendeereferral = config.getUrlParams('attendeereferral') || '';

  const setFirstRegisteredSession = () => {
    const date = new Date();
    const today = date.toJSON().split('T')[0];

    const session = _.find(
      _.map(mySessions, (sessionId) => {
        return _.find(sessions, { id: sessionId });
      }),
      (session) => {
        return session && session.startdatetime.split('T')[0] >= today;
      }
    );
    setSession(session);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalType(null);
    ReactGA.event({
      category: `InfoModal: ${modalType} `,
      action: 'Close'
    });
    if (_.includes(['notEligible', 'maxCapacity'], modalType)) {
      window.location.href = '/upcoming-events';
    }
  };

  const addCampaignRegistrant = () => {
    const utm_term = config.getUrlParams('utm_term');
    if (!utm_term) {
      return;
    }
    config.fetch(`${config.baseApiUrl}/api/campaignregistrations`, {
      method: 'POST',
      headers: auth.headers,
      body: JSON.stringify({ utm_term, user_email: auth.user.email })
    });
    // try {
    //   const utm_term = config.getUrlParams('utm_term');
    //   if (!utm_term) {
    //     return;
    //   }
    //   const script = document.createElement('script');
    //   script.src = `http://track.consultant360.com/?xx=YE0lrPo4ZFH8ZFMiYvKfXE0%3Dj&utm_term=${utm_term}`;
    //   // console.log('scrept.src', script.src);
    //   script.async = true;
    //   document.body.appendChild(script);
    // } catch (tag_error) {
    //   console.log(tag_error);
    // }
  };

  React.useEffect(() => {
    // console.log('in useffect', sessions, mySessions, loadingState, data.loaded);
    if (data.loaded && !loadingState.loading && !loadingState.loaded) {
      // console.log('code', code, 'session', session);
      if (code && !session) {
        setLoadingState({ ...loadingState, loading: true });
        const request = config.fetch(`${config.baseApiUrl}/api/toggleregistration`, {
          method: 'POST',
          headers: auth.headers,
          body: JSON.stringify({
            code,
            confirmed_non_affiliation,
            isVirtualHybrid,
            attendeereferral,
            getSessionOnly: isVideoOpen,
            isHwpMeeting: !code.match(/[A-Z0-9]{6}/),
            isHWPRegister: data.mySessions.indexOf(code) < 0
          })
        });
        request.then((response) => {
          setLoadingState({ loading: false, loaded: true });

          if (response.status === 404) {
            setFirstRegisteredSession();
            return;
          }
          if (response.status === 406) {
            // alert('max sessions reached popup');
            setModalType('maxCapacity');
            setModalOpen(true);
            ReactGA.event({
              category: 'Session Registration RSVP',
              action: `User tried to register for meeting at max capacity (${code})`
            });
            setFirstRegisteredSession();
            return;
          }
          if (response.status === 401) {
            // alert('max sessions reached popup');
            setModalType('maxAttendedMeetings');
            setModalOpen(true);
            ReactGA.event({
              category: 'Session Registration RSVP',
              action: `User Attended more than 3 meetings (${code})`
            });
            setFirstRegisteredSession();
            return;
          }
          if (response.status === 403) {
            // alert('office staff cant register for out of office');
            setModalType('notEligible');
            setModalOpen(true);
            ReactGA.event({
              category: 'Session Registration RSVP',
              action: `User not allowed to register for out of office sessions (${code})`
            });
            setFirstRegisteredSession();
            return;
          }
          if (config.webcastCodes.includes(code) && response.status === 201) {
            addCampaignRegistrant();
          }

          response.json().then((responseData) => {
            if (response.status === 206) {
              if (!isVideoOpen) {
                setRegisterModalOpen(true);
              }
              setSession(responseData);
              return;
            }
            const { mySessions, sessions } = responseData;
            setData({ mySessions, sessions });
            setSession(_.find(sessions, { invitationcode: code }));
          });
        });
        ReactGA.event({
          category: 'Session Registration RSVP',
          action: `RSVP session registration ${code}`
        });
        // if (config.webcastCodes.includes(code)) {
        //   gtagConversion('DC-9785414/event0/itci-000+per_session');
        //   try {
        //     window.uetq = window.uetq || [];
        //     window.uetq.push('event', 'engage', {
        //       event_label: 'hcp',
        //       event_category: 'form-submit'
        //     });
        //     const script = document.createElement('script');
        //     script.src = 'https://hmpads.advertserve.com/servlet/track?mid=15453&javascript=true';
        //     script.async = true;
        //     document.body.appendChild(script);
        //   } catch (tag_error) {
        //     console.log(tag_error);
        //   }
        // }
      } else if (!code && !session && mySessions.length) {
        setLoadingState({ ...loadingState, loading: true });
        setFirstRegisteredSession();
        setLoadingState({ loading: false, loaded: true });
      }
    }
  }, [auth, code, session, mySessions, sessions, setData, loadingState]); // eslint-disable-line

  // console.log('session', session, 'modalOpen', modalOpen);

  if (modalOpen) {
    return <InfoModal handleClose={handleModalClose} modalType={modalType} open={modalOpen} />;
  }

  if (!session) {
    return '';
  }
  return (
    <>
      <SessionCard
        style={{ width: '100%', maxWidth: '100%' }}
        session={session}
        registerModalOpen={registerModalOpen}
        isVideoOpen={isVideoOpen}
      />
    </>
  );
};

const mapStateToProps = ({ auth, data }) => {
  return { auth, data };
};

export default connect(mapStateToProps, { setData })(TopSessionComponent);
