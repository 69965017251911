import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { Modal } from 'antd';

import { isMobile } from 'react-device-detect';

import BorderButton from './BorderButton';
import RepContactTopics from './RepContactTopics';

import config from '../../config';

const modalStyles = {
  contentStyle: {
    padding: '20px 5px',
    textAlign: 'center',
    justifyContent: 'center',
    fontWeight: 800,
    fontSize: 20,
    color: '#00597D'
  }
};

const RepContactRequest = ({ visible, onClose, auth }) => {
  const [step, setStep] = useState('topicSelect');
  const [topics, setTopics] = useState([]);

  const handleTopicSelect = (topicValue) => {
    return (e) => {
      // console.log('topics', topics);
      if (e.target.checked) {
        setTopics([...topics, topicValue]);
      } else {
        setTopics(topics.filter((topic) => topic !== topicValue));
      }
    };
  };

  const onModalClose = () => {
    setStep('topicSelect');
    setTopics([]);
    onClose();
  };

  const contactRequest = () => {
    ReactGA.event({
      category: 'Contact A Representative',
      action: 'Contact A Representative'
    });
    config.fetch(`${config.baseApiUrl}/api/contactrep`, {
      method: 'POST',
      headers: auth.headers,
      body: JSON.stringify({
        external_id: auth.user.external_id,
        hwp_user_id: auth.user.hwp_user_id,
        topics: topics
      })
    });
  };

  const renderModalText = () => {
    if (step === 'topicSelect') {
      return (
        <div style={modalStyles.contentStyle}>
          We greatly appreciate your interest in connecting with an Intra-Cellular Therapies
          representative. In order to make the best use of your time, please select from the
          following topics for appropriate follow-up:
          <br />
          <br />
          <RepContactTopics topics={topics} handleTopicSelect={handleTopicSelect} />
          <div style={{ marginTop: 20, fontSize: 16 }}>
            Please click &quot;Confirm&quot; to send your inquiry to <br />
            an Intra-Cellular Therapies representative.
          </div>
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'space-between'
              // border: '1px solid green'
            }}
          >
            <BorderButton
              onClick={() => {
                if (topics.length > 0) {
                  contactRequest();
                  setStep('contactInfo');
                }
              }}
              disabled={topics.length === 0}
              wrapperStyle={{ marginBottom: 5 }}
              buttonStyle={{ padding: 10 }}
            >
              <span style={{ fontSize: 14 }}>CONFIRM</span>
            </BorderButton>
            &nbsp;
            <BorderButton
              wrapperStyle={{ marginBottom: 5 }}
              buttonStyle={{ padding: 10 }}
              onClick={onModalClose}
            >
              <span style={{ fontSize: 14 }}>CANCEL</span>
            </BorderButton>
          </div>
        </div>
      );
    }
    return (
      <div style={{ ...modalStyles.contentStyle, fontSize: 24 }}>
        Thank you. Your request has been sent. An Intra-Cellular Therapies Representative will be in
        contact with you soon.
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
          <BorderButton
            wrapperStyle={{ marginBottom: 5 }}
            buttonStyle={{ padding: 10 }}
            onClick={onModalClose}
          >
            <span style={{ fontSize: 16 }}>CLOSE</span>
          </BorderButton>
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      width={step === 'topicSelect' ? 650 : 500}
      style={{ padding: isMobile ? null : 50, height: 500 }}
      onClose={onModalClose}
      onCancel={onModalClose}
      destroyOnClose
      footer={null}
    >
      {renderModalText()}
    </Modal>
  );
};

export default RepContactRequest;
